import GATSBY_COMPILED_MDX from "/home/runner/work/discretize.eu/discretize.eu/discretize-guides/cm-guides/ranger/power-soulbeast/pug.md";
import {withGw2Theme} from '../utils/withGw2Theme';
import {Box, Grid, ListItem, ListItemText, Typography, useMediaQuery, useTheme} from '@mui/material';
import {graphql} from 'gatsby';
import React from 'react';
import {makeStyles} from 'tss-react/mui';
import Template from '.';
import BossCard from '../components/cm-guides/BossCard';
import {Specialization} from '../components/gw2components';
import GuideHeader from '../components/navigation/GuideHeader';
import Link from '../components/navigation/Link';
import Navbar from '../components/navigation/Navbar';
import SimpleList from '../components/SimpleList';
import ProfessionContext from '../state/context/ProfessionContext';
const useStyles = makeStyles()(theme => ({
  gridItem: {
    padding: theme.spacing(1)
  },
  list: {
    marginTop: theme.spacing(1)
  },
  linkContainer: {
    paddingLeft: theme.spacing(1)
  }
}));
const createDefaultDescription = title => `Learn everything about ${title} in Guild Wars 2. Our general guides are designed to improve your knowledge of game basics.`;
const ThemedMarkdown = withGw2Theme()(({markdown}) => React.createElement(React.Fragment, null, markdown));
function GuideTemplate({location, children, data: {cmguide: {fields: {slug, guideGroup}, frontmatter: {title, type: parentType, description: customDescription, date, author, profession, specialization, disableBosses}}, otherGuides}}) {
  const {classes} = useStyles();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const BOSSES = ['MAMA', 'Siax', 'Ensolyss', 'Skorvald', 'Artsariiv', 'Arkk', 'LightAi', 'DarkAi'];
  const otherDifficulties = otherGuides.group.find(({edges}) => edges[0].node.fields.guideGroup === guideGroup)?.edges.filter(({node: {frontmatter: {type}}}) => type !== parentType).map(({node: {slug: guideSlug, frontmatter: {type}}}) => ({
    slug: `/${guideSlug}`,
    type
  }));
  const renderHeader = () => {
    let bosses = BOSSES;
    if (disableBosses) {
      bosses = bosses.filter(b => !disableBosses.includes(b));
    }
    return React.createElement(GuideHeader, {
      title: title,
      date: date.trim(),
      author: author,
      icon: React.createElement(Specialization, {
        disableText: true,
        name: specialization,
        size: "big"
      }),
      profession: profession
    }, React.createElement(Box, {
      display: "flex",
      className: classes.linkContainer
    }, React.createElement(Box, {
      flexGrow: 1
    }, React.createElement(Typography, {
      component: "span"
    }, title)), otherDifficulties && otherDifficulties.length > 0 && React.createElement(Box, {
      alignSelf: "end"
    }, React.createElement(Typography, {
      variant: "caption"
    }, " Guide variants:", ' ', otherDifficulties.map(({slug: otherGuideSlug, type}) => React.createElement(Link, {
      key: otherGuideSlug,
      to: otherGuideSlug
    }, type, ' '))))), React.createElement(Grid, {
      container: true
    }, bosses.map(b => React.createElement(Grid, {
      item: true,
      xs: true,
      className: classes.gridItem,
      key: b
    }, React.createElement(BossCard, {
      profession: profession,
      link: `#${b.toLowerCase()}`,
      name: b
    }))), isBigScreen && bosses.length < 7 && [...Array(7 - bosses.length).keys()].map((_, i) => React.createElement(Grid, {
      item: true,
      xs: true,
      key: `placeholder_${i.toString()}`
    }))));
  };
  const wrapMarkdown = markdown => {
    return React.createElement(React.Fragment, null, React.createElement(ProfessionContext.Provider, {
      value: specialization
    }, React.createElement(SimpleList, {
      className: classes.list
    }, React.createElement(ListItem, null, React.createElement(ListItemText, null, " Please check the", ' ', React.createElement(Link, {
      to: "/guides/cc-distribution"
    }, "CC distribution"), " to contribute your share of the necessary CC!")), React.createElement(ListItem, null, React.createElement(ListItemText, null, " Please also read through the fractal pages for general encounter information!"))), React.createElement(ThemedMarkdown, {
      profession: profession,
      markdown: markdown
    })));
  };
  const description = customDescription || createDefaultDescription(title);
  return React.createElement(Template, {
    location: location,
    SeoProps: {
      title,
      description
    },
    markdown: children,
    renderHeader: () => React.createElement(Navbar, null, " ", renderHeader(), " "),
    wrapMarkdown: wrapMarkdown
  });
}
GuideTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(GuideTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query CMGuideQuery($id: String!) {
    cmguide: mdx(id: { eq: $id }) {
      fields {
        slug
        guideGroup
      }
      frontmatter {
        title
        type
        description
        date
        author
        profession
        specialization
        disableBosses
      }
    }
    otherGuides: allMdx(filter: { fields: { section: { eq: "cm-guides" } } }) {
      group(field: fields___guideGroup) {
        edges {
          node {
            id
            frontmatter {
              specialization
              title
              type
            }
            fields {
              slug
              guideGroup
            }
          }
        }
      }
    }
  }
`;
