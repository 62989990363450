/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    h3: "h3",
    strong: "strong",
    em: "em"
  }, _provideComponents(), props.components), {Boss, Warning, Skill, Trait, Effect, Boon, Item, Specialization, SpecialActionKey, Condition} = _components;
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Boss) _missingMdxReference("Boss", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Effect) _missingMdxReference("Effect", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!SpecialActionKey) _missingMdxReference("SpecialActionKey", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Warning) _missingMdxReference("Warning", true);
  return React.createElement(React.Fragment, null, React.createElement(Boss, {
    name: "mama",
    video: "",
    videoCreator: "",
    foodId: "43360",
    utilityId: "50082",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12497",
    utility3Id: "12491",
    eliteId: "45717",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Longbow",
    weapon1MainSigil1: "force",
    weapon1MainSigil2: "Severance",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Axe",
    weapon2OffSigil: "Impact",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Sword",
    weapon2MainSigil1: "Force",
    weapon2MainInfusion1Id: "37131",
    foodId: "43360",
    utilityId: "50082",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12497",
    utility3Id: "12491",
    eliteId: "45717",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46765",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "84505",
    weapon2MainId: "46774",
    weapon2MainSigil1Id: "24615",
    weapon2OffId: "46759",
    weapon2OffSigilId: "24868"
  }, React.createElement(Warning, null, React.createElement(_components.p, null, "This fight gets very messy if the adds are not immediately CCed. Especially at this fight it is recommended to grab extra CC skills to make up for the lack of CC of your team mates.")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pet: ", React.createElement(Skill, {
    id: "43636",
    disableText: true,
    id: "43636"
  }), " Rock Gazelle."), "\n", React.createElement(_components.li, null, "Take ", React.createElement(Trait, {
    id: "2143",
    id: "2143"
  }), "."), "\n")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "The timing of ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " later on in the fight will depend on your groups DPS and when you first cast it. If it is off cooldown at the start of phase 3 use it then. If not use it after you have casted ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " on P4.")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Your rotation will depend on your groups DPS and ability to CC! In general make sure you have as many damage modifiers up as you can when you do your burst and try to time it with the ", React.createElement(Effect, {
    name: "Exposed"
  }), " effect. Outside of your burst use skills with shorter cooldowns while you wait for your cooldowns to line up and try not to get stuck auto attacking on Longbow.")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Make sure you have ", React.createElement(Trait, {
    name: "Leader of the pack",
    id: "2128"
  }), " while precasting!"), "\n", React.createElement(_components.li, null, "If a fire field isn’t present, equip an offhand torch and use ", React.createElement(Skill, {
    id: "12504",
    id: "12504"
  }), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Moa Stance",
    id: "45970"
  }), " to grant your team free extra boon duration."), "\n", React.createElement(_components.li, null, "Summon ", React.createElement(Skill, {
    name: "Frost Spirit",
    id: "12497"
  }), " and use ", React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " to blast ", React.createElement(Boon, {
    name: "Might"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " then take ", React.createElement(_components.em, null, "Mistlock Singularity"), ", use ", React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " again\nand swap to Longbow."), "\n", React.createElement(_components.li, null, "As you run in cast ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " (for the second time) and swap traits to ", React.createElement(Trait, {
    name: "Oppressive Superiority",
    id: "2143"
  }), "."), "\n", React.createElement(_components.li, null, "Call ", React.createElement(Skill, {
    name: "Frost Spirit",
    id: "12497"
  }), " to you using ", React.createElement(Skill, {
    name: "Cold Snap",
    id: "12600"
  }), ", and cast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " as MAMA Spawns."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "1st Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Sicem",
    id: "12633"
  }), " as soon as MAMA is vulnerable."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " -> ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " to CC and proc ", React.createElement(Item, {
    id: "84505",
    id: "84505"
  }), "\n."), "\n", React.createElement(_components.li, null, "Swap weapons."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of scars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Whirling defense",
    id: "12639"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "1st Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Monarchs Leap"
  }), " to reach the add quickly."), "\n", React.createElement(_components.li, null, "Autoattack as well as using ", React.createElement(Skill, {
    name: "Worldly impact",
    id: "40729"
  }), " and ", React.createElement(Skill, {
    id: "41524",
    id: "41524"
  }), "."), "\n", React.createElement(_components.li, null, "If your group is slow you may have time to switch back to Longbow here and cast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), ". Only do this if you will have time to burst P2 and still swap back to Longbow after for the 2nd add."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "2nd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When you go back back to MAMA, autoattack on sword/axe. You can use ", React.createElement(Skill, {
    name: "Monarchs leap"
  }), " (or ", React.createElement(Skill, {
    id: "12481",
    id: "12481"
  }), " while facing backwards) to jump back to MAMA if you want."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of scars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " as they come\noff cooldown."), "\n", React.createElement(_components.li, null, "Swap weapons."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " to proc ", React.createElement(Item, {
    id: "84505",
    id: "84505"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  }), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " and ", React.createElement(Skill, {
    id: "41524",
    id: "41524"
  }), " if they come off cooldown, otherwise auto attack on Longbow for the rest of the phase."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "2nd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " to hit MAMA and the add."), "\n", React.createElement(_components.li, null, "Use a mix of ", React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  }), " and autoattacks to kill the add."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "3rd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Sicem",
    id: "12633"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " -> ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " to CC and proc ", React.createElement(Item, {
    id: "84505",
    id: "84505"
  }), "\n."), "\n", React.createElement(_components.li, null, "Swap weapons."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of scars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Whirling defense",
    id: "12639"
  }), "."), "\n", React.createElement(_components.li, null, "Once MAMA hits 25% you can use ", React.createElement(Skill, {
    name: "Monarchs Leap"
  }), " for a free leap away from the poison field and get to the add faster."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "3rd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Resummon your ", React.createElement(Skill, {
    id: "12497",
    id: "12497"
  }), " as it has probably died by now."), "\n", React.createElement(_components.li, null, "Swap weapons as soon as it is off cd (", React.createElement(Skill, {
    name: "Path of scars",
    id: "12638"
  }), " if you can before swapping)."), "\n", React.createElement(_components.li, null, "Autoattack as well as using ", React.createElement(Skill, {
    name: "Worldly impact",
    id: "40729"
  }), " and ", React.createElement(Skill, {
    id: "41524",
    id: "41524"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "4th Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Try to cast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " so it doesn't get interrupted by MAMA jumping."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " -> ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " to CC and proc ", React.createElement(Item, {
    id: "84505",
    id: "84505"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Sicem",
    id: "12633"
  }), " then ", React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  }), "."), "\n", React.createElement(_components.li, null, "Swap to axe as soon as possible and use ", React.createElement(Skill, {
    name: "Path of scars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Whirling defense",
    id: "12639"
  }), "."), "\n"), "\n", React.createElement(Boss, {
    name: "siax",
    foodId: "43360",
    utilityId: "50082",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12497",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Greatsword",
    weapon1MainSigil1: "force",
    weapon1MainSigil2: "Serpentslaying",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Axe",
    weapon2OffSigil: "Impact",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Sword",
    weapon2MainSigil1: "Serpentslaying",
    weapon2MainInfusion1Id: "37131",
    foodId: "43360",
    utilityId: "50082",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12497",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46762",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "24658",
    weapon2MainId: "46774",
    weapon2MainSigil1Id: "24658",
    weapon2OffId: "46759",
    weapon2OffSigilId: "24868"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pet: ", React.createElement(Skill, {
    id: "43636",
    disableText: true,
    id: "43636"
  }), " Rock Gazelle."), "\n", React.createElement(_components.li, null, "Take ", React.createElement(Trait, {
    id: "2128",
    id: "2128"
  }), ", ", React.createElement(Trait, {
    name: "Two handed training",
    id: "1047"
  }), "."), "\n", React.createElement(_components.li, null, "Remember to use ", React.createElement(Item, {
    id: "24658",
    id: "24658"
  }), " for this encounter!"), "\n", React.createElement(_components.li, null, "Longbow will burst higher on this fight, but you will have trouble killing your 2nd add. You should only play it if you know you can quickly kill the add or you have help."), "\n")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "The timing of ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " in this fight will depend on your group. If your group has low DPS and/or is running into the fight use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " just before the CC bar appears on P1 and again at the start of P3. If your group is portalling into the fight and has reasonable DPS cast ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " as late as you can in the precast, then save it for the start of P2.")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "If your P1 is slow you may have time to swap to Greatsword for the first split phase. When you go back to Siax use ", React.createElement(Skill, {
    id: "46629",
    id: "46629"
  }), " -> ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " to burst into ", React.createElement(Effect, {
    name: "Exposed"
  }), ".")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Your rotation will depend on your groups DPS and ability to CC! In general make sure you have as many damage modifiers up as you can when you do your burst and try to time it with the ", React.createElement(Effect, {
    name: "Exposed"
  }), " effect. Outside of your burst use skills with shorter cooldowns while you wait for your cooldowns to line up.")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), " before the fight starts."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Moa Stance",
    id: "45970"
  }), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " to blast ", React.createElement(Boon, {
    name: "Might"
  }), ", then use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), "."), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity"), " and swap to Longbow."), "\n", React.createElement(_components.li, null, "Summon ", React.createElement(Skill, {
    name: "Frost spirit",
    id: "12497"
  }), "."), "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " and swap to Greatsword as the yellow cast bar appears."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "1st Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Frosttrap",
    id: "12492"
  }), " as soon as your first trap procs so you have both ticking\nat the same time."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Sicem",
    id: "12633"
  }), " as soon as Siax is targetable."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "46629",
    id: "46629"
  }), " -> ", React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " -> ", React.createElement(Skill, {
    id: "41524",
    id: "41524"
  }), " before\nthe breakbar."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " followed by ", React.createElement(Skill, {
    name: "Hilt Bash",
    id: "12475"
  }), " to CC."), "\n", React.createElement(_components.li, null, "Burst with ", React.createElement(Skill, {
    id: "46629",
    id: "46629"
  }), " -> ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "1st Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    id: "12482",
    id: "12482"
  }), " to get to the add quickly."), "\n", React.createElement(_components.li, null, "Stay on Sword and use auto attacks and your pet skills to kill it."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "2nd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "You can use ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " or ", React.createElement(Skill, {
    name: "Hornet Sting"
  }), " to get back to Siax quickly."), "\n", React.createElement(_components.li, null, "As soon as they come off cooldown use ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " and ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), "."), "\n", React.createElement(_components.li, null, "Weapon swap and use ", React.createElement(Skill, {
    id: "46629",
    id: "46629"
  }), " -> ", React.createElement(Skill, {
    name: "Hilt Bash",
    id: "12475"
  }), " -> ", React.createElement(Skill, {
    id: "46629",
    id: "46629"
  }), " and your pet skills to finish the phase."), "\n", React.createElement(_components.li, null, "Your ", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), " and ", React.createElement(Skill, {
    name: "Frost Trap",
    id: "12492"
  }), " may come off cooldown at the start of or during the phase, feel free to use them if you think they will be back off cooldown for P3."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "2nd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    id: "12521",
    id: "12521"
  }), " to get to your add quickly."), "\n", React.createElement(_components.li, null, "Kill your add using a combination of ", React.createElement(Skill, {
    id: "46629",
    id: "46629"
  }), ", ", React.createElement(Skill, {
    name: "Hilt Bash",
    id: "12475"
  }), " and your Pet Skills."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "3rd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "sicem",
    id: "12633"
  }), " once Siax is vulnerable."), "\n", React.createElement(_components.li, null, "Burst with ", React.createElement(Skill, {
    id: "46629",
    id: "46629"
  }), " -> ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Frost trap",
    id: "12492"
  }), " -> ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), "."), "\n", React.createElement(_components.li, null, "Auto attack and use your pet skills as they come off cooldown until Siax is dead."), "\n", React.createElement(_components.li, null, "In a group with low DPS you will have time to swap back to Greatsword, so finish the fight with ", React.createElement(Skill, {
    id: "46629",
    id: "46629"
  }), " -> ", React.createElement(Skill, {
    name: "Hilt Bash",
    id: "12475"
  }), " -> ", React.createElement(Skill, {
    id: "46629",
    id: "46629"
  }), "."), "\n", React.createElement(_components.li, null, "You might also have to break a CC bar in this phase so try to use ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), ", ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), ", ", React.createElement(Skill, {
    name: "hiltbash",
    id: "12475"
  }), " to help."), "\n"), "\n", React.createElement(Boss, {
    name: "ensolyss",
    foodId: "91805",
    utilityId: "50082",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12497",
    utility3Id: "12491",
    eliteId: "45717",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Longbow",
    weapon1MainSigil1: "serpentslaying",
    weapon1MainSigil2: "Force",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Axe",
    weapon2OffSigil: "Impact",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Sword",
    weapon2MainSigil1: "Serpentslaying",
    weapon2MainInfusion1Id: "37131",
    foodId: "91805",
    utilityId: "50082",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12497",
    utility3Id: "12491",
    eliteId: "45717",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46765",
    weapon1MainSigil1Id: "24658",
    weapon1MainSigil2Id: "24615",
    weapon2MainId: "46774",
    weapon2MainSigil1Id: "24658",
    weapon2OffId: "46759",
    weapon2OffSigilId: "24868"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pet: ", React.createElement(Skill, {
    id: "43636",
    disableText: true,
    id: "43636"
  }), " Rock Gazelle."), "\n", React.createElement(_components.li, null, "Take ", React.createElement(Trait, {
    id: "2128",
    id: "2128"
  }), "."), "\n", React.createElement(_components.li, null, "Remember to use ", React.createElement(Item, {
    id: "24658",
    id: "24658"
  }), " for this encounter!"), "\n")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "The following guide is assuming your group doesn't instabreak the first CC bar. If you are going to instabreak you should refer to the static opener then follow the rest of the rotation from this guide.")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Your rotation will depend on your groups DPS and ability to CC! In general make sure you have as many damage modifiers up as you can when you do your burst and try to time it with the ", React.createElement(Effect, {
    name: "Exposed"
  }), " effect. Outside of your burst use skills with shorter cooldowns while you wait for your cooldowns to line up and try not to get stuck auto attacking on Longbow.")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Moa Stance",
    id: "45970"
  }), " to grant your team free extra boon duration."), "\n", React.createElement(_components.li, null, "If a fire field isn’t present, equip an offhand torch and use ", React.createElement(Skill, {
    id: "12504",
    id: "12504"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " to blast ", React.createElement(Boon, {
    name: "Might"
  }), ", ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " then take ", React.createElement(_components.em, null, "Mistlock Singularity"), " and ", React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " again."), "\n", React.createElement(_components.li, null, "Swap to Longbow and take the portal the the platform."), "\n", React.createElement(_components.li, null, "As you are about to spawn Ensolyss summon ", React.createElement(Skill, {
    name: "Frost spirit",
    id: "12497"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 1")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), ", ", React.createElement(Skill, {
    name: "Pointblankshot",
    id: "12511"
  }), " for instant CC. Start casting ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " just before Ensolyss becomes vulnerable so you can hit both CC skills."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  }), " and ", React.createElement(Skill, {
    id: "41524",
    id: "41524"
  }), ", move into the bubble as it spawns."), "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " and towards the end of the cast use ", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), "."), "\n", React.createElement(_components.li, null, "When the bar reappears after the bubble use a CC skills to help break it."), "\n", React.createElement(_components.li, null, "Once it's broken burst with ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), ", ", React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  }), ", cancel it half way through and use ", React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), ", weapon swap, ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), "."), "\n", React.createElement(_components.li, null, "Autoattack until your ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " is off cool down again and then weapons swap and use ", React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  }), "."), "\n", React.createElement(_components.li, null, "At this point Ensolyss should dash so cast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " mid and then continue with your normal rotation until Ensolyss phases."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Orb Phases")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "We heal as one",
    id: "31914"
  }), " and ", React.createElement(Skill, {
    name: "worldlyimpact",
    id: "40729"
  }), " to keep up ", React.createElement(Boon, {
    name: "alacrity"
  }), " on yourself."), "\n", React.createElement(_components.li, null, "Re summon ", React.createElement(Skill, {
    name: "Frost Spirit",
    id: "12497"
  }), " if it has died."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 2")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Call ", React.createElement(Skill, {
    name: "Frost Spirit",
    id: "12497"
  }), " mid with ", React.createElement(Skill, {
    name: "Cold Snap",
    id: "12600"
  }), ", and if you don't have any ", React.createElement(Boon, {
    name: "Quickness"
  }), " use ", React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " to generate some."), "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " as Ensolyss spawns, and towards the end of the cast use ", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "41524",
    id: "41524"
  }), " -> ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), "."), "\n", React.createElement(_components.li, null, "CC with ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " and ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), "."), "\n", React.createElement(_components.li, null, "Burst with ", React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  }), ", cancel it half way through and use ", React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), ", weapon swap, ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), "."), "\n", React.createElement(_components.li, null, "Auto attack until your ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " is off cool down again and then weapons swap and use ", React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  }), "."), "\n", React.createElement(_components.li, null, "Make sure you are stood in a safe place to cast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " again and continue with the standard rotation until Ensolyss phases."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Orb Phases")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "We heal as one",
    id: "31914"
  }), " and ", React.createElement(Skill, {
    name: "worldlyimpact",
    id: "40729"
  }), " to keep up ", React.createElement(Boon, {
    name: "alacrity"
  }), " on yourself."), "\n", React.createElement(_components.li, null, "Re summon ", React.createElement(Skill, {
    name: "Frost Spirit",
    id: "12497"
  }), " if it has died."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 3")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Repeat the same as Phase 2."), "\n", React.createElement(_components.li, null, "Remember to Call ", React.createElement(Skill, {
    name: "Frost Spirit",
    id: "12497"
  }), " to you with ", React.createElement(Skill, {
    name: "Cold Snap",
    id: "12600"
  }), " at 15%, and don't cry to much from being stuck on Longbow for most of the ", React.createElement(Effect, {
    name: "Exposed"
  }), " effect."), "\n"), "\n", React.createElement(Boss, {
    name: "skorvald",
    video: "o7R-tnLH4ws",
    videoCreator: "Elu",
    foodId: "91805",
    utilityId: "9443",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12497",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Longbow",
    weapon1MainSigil1: "force",
    weapon1MainSigil2: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Axe",
    weapon2OffSigil: "Impact",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Sword",
    weapon2MainSigil1: "Force",
    weapon2MainInfusion1Id: "37131",
    video: "o7R-tnLH4ws",
    videoCreator: "Elu",
    foodId: "91805",
    utilityId: "9443",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12497",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46765",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "24868",
    weapon2MainId: "46774",
    weapon2MainSigil1Id: "24615",
    weapon2OffId: "46759",
    weapon2OffSigilId: "24868"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pet: ", React.createElement(Skill, {
    id: "43636",
    disableText: true,
    id: "43636"
  }), " Rock Gazelle."), "\n", React.createElement(_components.li, null, "Take ", React.createElement(Trait, {
    id: "2128",
    id: "2128"
  }), "."), "\n", React.createElement(_components.li, null, "With good instabilities, use ", React.createElement(Item, {
    id: "73191",
    id: "73191"
  }), " as Utility food."), "\n", React.createElement(_components.li, null, "You can swap ", React.createElement(Item, {
    id: "24868",
    id: "24868"
  }), " for ", React.createElement(Item, {
    id: "24661",
    id: "24661"
  }), " in your longbow if you want."), "\n", React.createElement(_components.li, null, "Warhorn can be a replacement for Longbow here, ", React.createElement(Skill, {
    name: "Callofthewild",
    id: "12621"
  }), " is a blast finisher allowing you to stack ", React.createElement(Boon, {
    name: "Might"
  }), " on the islands. You simply use ", React.createElement(Skill, {
    id: "12620",
    id: "12620"
  }), " before you start attacking in each phase."), "\n")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Your rotation will depend on your groups DPS and ability to CC! In general make sure you have as many damage modifiers up as you can when you do your burst and try to time it with the ", React.createElement(Effect, {
    name: "Exposed"
  }), " effect. Outside of your burst use skills with shorter cooldowns while you wait for your cooldowns to line up and try not to get stuck auto attacking on Longbow.")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Spirit Management:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If you run with a healer you can summon it on the platform and you can forget about it as it will probably stay alive the whole fight (Obviously if it dies resummon it!)."), "\n", React.createElement(_components.li, null, "If you run without it depends on how your ", React.createElement(Specialization, {
    name: "Renegade"
  }), " plays, if they precast ", React.createElement(Skill, {
    id: "45686",
    id: "45686"
  }), " before Skorvald spawns then you can summon your ", React.createElement(Skill, {
    name: "Frostspirit",
    id: "12497"
  }), " down on the boss platform after the precast and the Kalla heal skill will sustain it until the second phase. In this scenario you don't teleport it at all you just leave it behind."), "\n", React.createElement(_components.li, null, "If they don't do this then you will want to summon the spirit next to the Boss Challenge Mote and let it tick down in health for a couple of ticks and then pull it firstly down to Skorvald after the precast and then to the first island using ", React.createElement(Skill, {
    name: "Cold Snap",
    id: "12600"
  }), " in order to kill it so it is back up for island 4 to be resummoned. This is the method described in the guide below."), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When you are activating the Boss Challenge Mote run down to the boss platform and precast ", React.createElement(Skill, {
    name: "Frosttrap",
    id: "12492"
  }), " on the boss like a ", React.createElement(Specialization, {
    name: "Dragonhunter"
  }), " in disguise. Then /gg and wait up by the Mote until you are ready to precast."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Moa Stance",
    id: "45970"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " to blast ", React.createElement(Boon, {
    name: "Might"
  }), ", ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " then take ", React.createElement(_components.em, null, "Mistlock Singularity"), " and ", React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " again."), "\n", React.createElement(_components.li, null, "Swap to Longbow and run to the platform. As you run summon your ", React.createElement(Skill, {
    name: "Frost Spirit",
    id: "12497"
  }), "."), "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "100%-66%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When your first ", React.createElement(Skill, {
    name: "Frosttrap",
    id: "12492"
  }), " procs cast it again and use ", React.createElement(Skill, {
    name: "Onewolfpack",
    id: "45717"
  }), "."), "\n", React.createElement(_components.li, null, "As soon as Skorvald is targetable, use ", React.createElement(Skill, {
    name: "Sicem",
    id: "12633"
  }), ", ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " and ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " to CC."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " and then ", React.createElement(Skill, {
    name: "Whirlingdefense",
    id: "12639"
  }), " into ", React.createElement(Effect, {
    name: "Exposed"
  }), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), ", auto attack and your pet skills if Skorvald hasn't phased."), "\n", React.createElement(_components.li, null, "Place a portal if you volunteered to do 66%."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Hornet Sting"
  }), " to get to the first island once it is phased."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Islands")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Remember to use ", React.createElement(Skill, {
    name: "We Heal as One",
    id: "31914"
  }), " to keep up boons."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Worldlyimpact",
    id: "40729"
  }), " and autoattacks to kill the first add."), "\n", React.createElement(_components.li, null, "You can use ", React.createElement(Skill, {
    name: "whirlingdefense",
    id: "12639"
  }), " on the 2nd one after it jumps."), "\n", React.createElement(_components.li, null, "Swap to longbow and kill the 3rd add with ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " and ", React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  }), "."), "\n", React.createElement(_components.li, null, "As soon as you land on the last island, open portal if you placed it. Help to kill the last add and use ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), " or ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " to proc ", React.createElement(Trait, {
    name: "Twice as Vicious",
    id: "2127"
  }), "."), "\n", React.createElement(_components.li, null, "Take the portal early to precast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " before Skorvald becomes vulnerable."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "66%-33%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Frosttrap",
    id: "12492"
  }), ", ", React.createElement(Skill, {
    name: "Sicem",
    id: "12633"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " as your party arrive through the portal."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " then weaponswap."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "pathofscars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Whirlingdefense",
    id: "12639"
  }), "."), "\n", React.createElement(_components.li, null, "If Skorvald doesn't phase carry on with the normal rotation."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "33%-0%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Same as 66% to 33%."), "\n"), "\n", React.createElement(Boss, {
    name: "artsariiv",
    video: "o7R-tnLH4ws",
    timestamp: "112",
    videoCreator: "Elu",
    foodId: "91805",
    utilityId: "9443",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12497",
    utility3Id: "12491",
    eliteId: "45717",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Longbow",
    weapon1MainSigil1: "force",
    weapon1MainSigil2: "Severance",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Axe",
    weapon2OffSigil: "Impact",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Sword",
    weapon2MainSigil1: "Force",
    weapon2MainInfusion1Id: "37131",
    video: "o7R-tnLH4ws",
    videoCreator: "Elu",
    foodId: "91805",
    utilityId: "9443",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12497",
    utility3Id: "12491",
    eliteId: "45717",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46765",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "84505",
    weapon2MainId: "46774",
    weapon2MainSigil1Id: "24615",
    weapon2OffId: "46759",
    weapon2OffSigilId: "24868"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pet: ", React.createElement(Skill, {
    id: "43636",
    disableText: true,
    id: "43636"
  }), " Rock Gazelle."), "\n", React.createElement(_components.li, null, "Take ", React.createElement(Trait, {
    name: "Oppressive Superiority",
    id: "2143"
  }), "."), "\n", React.createElement(_components.li, null, "With good instabilities, use ", React.createElement(Item, {
    id: "73191",
    id: "73191"
  }), " as Utility food."), "\n", React.createElement(_components.li, null, "If your group is struggling with conditions use ", React.createElement(Skill, {
    name: "Healing Spring",
    id: "12489"
  }), "."), "\n", React.createElement(_components.li, null, "If you don't trust stability swap ", React.createElement(Skill, {
    name: "Signet of the Wild",
    id: "12491"
  }), " for ", React.createElement(Skill, {
    name: "Dolyak Stance",
    id: "45789"
  }), " (If you want to be PMA and share it play ", React.createElement(Trait, {
    name: "Leader of the Pack",
    id: "2128"
  }), ")."), "\n")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "If you group is going to skip the first anomaly, follow the rotation for side strat in the static section.")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "This guide assumes you are going to let your group kill the 2nd and 3rd anomalies or skip them completely. If you get the bomb don't panic. Attempt to get the reflect and then if you have time special action key to the dome or /gg! If your group can't kill the anomaly without you you can still attempt to reflect. When you get to the side use ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), ", quickly weapon swap and ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), ".")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "If your group has fast or organised CC on the split phases, your ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " probably won't be off cooldown in the middle. If this is the case use ", React.createElement(Skill, {
    name: "pathofscars",
    id: "12638"
  }), " and swap to Longbow and proc ", React.createElement(Item, {
    id: "84505",
    id: "84505"
  }), " with ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), ". Then use ", React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  }), " and your pet skills.")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Your rotation will depend on your groups DPS and ability to CC! In general make sure you have as many damage modifiers up as you can when you do your burst and try to time it with the ", React.createElement(Effect, {
    name: "Exposed"
  }), " effect. Outside of your burst use skills with shorter cooldowns while you wait for your cooldowns to line up and try not to get stuck auto attacking on Longbow.")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Drop down to the boss platform and precast ", React.createElement(Skill, {
    name: "Frosttrap",
    id: "12492"
  }), " on the boss like a ", React.createElement(Specialization, {
    name: "Dragonhunter"
  }), " in disguise. Then /gg and wait up by the Singularity until you are ready to precast."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Moa Stance",
    id: "45970"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " to blast ", React.createElement(Boon, {
    name: "Might"
  }), ", ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " then take ", React.createElement(_components.em, null, "Mistlock Singularity"), " and ", React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " again."), "\n", React.createElement(_components.li, null, "Summon ", React.createElement(Skill, {
    name: "Frost SPirit",
    id: "12497"
  }), " in the middle of the platform (Ideally so it wont get hit by Artsariivs lasers)."), "\n", React.createElement(_components.li, null, "Start the fight as soon as you can and cast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  })), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "100-66%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Help kill the anomaly mid than special action key to the side."), "\n", React.createElement(_components.li, null, "Call ", React.createElement(Skill, {
    name: "Frost Spirit",
    id: "12497"
  }), " to you with ", React.createElement(Skill, {
    name: "Cold Snap",
    id: "12600"
  }), ", Then use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " and ", React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), "."), "\n", React.createElement(_components.li, null, "CC with ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " and ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), "."), "\n", React.createElement(_components.li, null, "Burst with ", React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  }), " -> ", React.createElement(Skill, {
    name: "Worldly Impact",
    id: "40729"
  }), " -> ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), "."), "\n", React.createElement(_components.li, null, "Continue with the normal rotation until Artsariiv phases."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Add Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "We Heal as One",
    id: "31914"
  }), " to keep up boons on yourself."), "\n", React.createElement(_components.li, null, "Make sure you are on Sword/Axe for the start of the next phase."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "66-33%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "As Artsariiv becomes vulnerable ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), "."), "\n", React.createElement(_components.li, null, "Dodge the shock wave and use ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " -> ", React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  })), "\n", React.createElement(_components.li, null, "Dodge the laser, ", React.createElement(Skill, {
    name: "We Heal as One",
    id: "31914"
  }), " to extend boons and special action key to the corner that Artsariiv will move to (it will be glowing purple)."), "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " and call ", React.createElement(Skill, {
    name: "Frost Spirit",
    id: "12497"
  }), " to you with ", React.createElement(Skill, {
    name: "Cold Snap",
    id: "12600"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Sic Em",
    id: "12633"
  }), " as soon as possible then use ", React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  })), "\n", React.createElement(_components.li, null, "Proc ", React.createElement(Item, {
    id: "84505",
    id: "84505"
  }), " with either ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " or ", React.createElement(Skill, {
    id: "45743",
    id: "45743"
  }), "."), "\n", React.createElement(_components.li, null, "Quickly weapon swap and use ", React.createElement(Skill, {
    name: "Path of Scars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Whirling Defense",
    id: "12639"
  }), " and pray for good reflect RNG (If your PuGs aren't doing anything weird you will get the reflect most of the time)."), "\n", React.createElement(_components.li, null, "If Artsariiv hasn't phased continue with your normal rotation."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "33 to 0%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Repeat the same rotation as 66-33%."), "\n"), "\n", React.createElement(Boss, {
    name: "arkk",
    video: "o7R-tnLH4ws",
    timestamp: "228",
    videoCreator: "Elu",
    foodId: "91805",
    utilityId: "50082",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12497",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Longbow",
    weapon1MainSigil1: "force",
    weapon1MainSigil2: "Severance",
    weapon1MainInfusion1Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Axe",
    weapon2OffSigil: "Impact",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Sword",
    weapon2MainSigil1: "Force",
    weapon2MainInfusion1Id: "37131",
    video: "o7R-tnLH4ws",
    videoCreator: "Elu",
    foodId: "91805",
    utilityId: "50082",
    healId: "31914",
    utility1Id: "12633",
    utility2Id: "12497",
    utility3Id: "12492",
    eliteId: "45717",
    weapon1MainInfusion1Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46765",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "84505",
    weapon2MainId: "46774",
    weapon2MainSigil1Id: "24615",
    weapon2OffId: "46759",
    weapon2OffSigilId: "24868"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pet: ", React.createElement(Skill, {
    id: "44617",
    disableText: true,
    id: "44617"
  }), " Red Moa."), "\n", React.createElement(_components.li, null, "Take ", React.createElement(Trait, {
    id: "2128",
    id: "2128"
  }), "."), "\n", React.createElement(_components.li, null, "Swap to ", React.createElement(Item, {
    id: "50082",
    id: "50082"
  }), "!"), "\n", React.createElement(_components.li, null, "Resummon your ", React.createElement(Skill, {
    name: "Frost spirit",
    id: "12497"
  }), " whenever you have downtime when you can't attack (i.e. whilst you are waiting for Arkk, the Archdiviner or Gladiator to spawn on the platform or during an orb phase if you have the time)."), "\n", React.createElement(_components.li, null, "Wherever possible you should try to hit multiple targets with your ", React.createElement(Skill, {
    name: "Pathofscars",
    id: "12638"
  }), ". (This can mean hitting an anomaly to help your ", React.createElement(Specialization, {
    name: "renegade"
  }), " kill it.)"), "\n", React.createElement(_components.li, null, "You can also pull the adds from the Archdiviner and Gladiator phase since your ", React.createElement(Specialization, {
    name: "Firebrand"
  }), " can only pull two at a time."), "\n", React.createElement(_components.li, null, "Pulling the adds is also great since they can hurt your teams ", React.createElement(Item, {
    id: "24836",
    id: "24836"
  }), " uptime."), "\n", React.createElement(_components.li, null, "If you drop on quickness for some reason, make sure to use ", React.createElement(Skill, {
    name: "Worldlyimpact",
    id: "40729"
  }), " before bursting to get yourself some."), "\n")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Your rotation will depend on your groups DPS and ability to CC! In general make sure you have as many damage modifiers up as you can when you do your burst and try to time it with the ", React.createElement(Effect, {
    name: "Exposed"
  }), " effect. Outside of your burst use skills with shorter cooldowns while you wait for your cooldowns to line up and try not to get stuck auto attacking on Longbow.")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Place a portal next to the ", React.createElement(_components.em, null, "Mistlock Singularity"), " and use ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " over to the boss platform, precast ", React.createElement(Skill, {
    name: "Frosttrap",
    id: "12492"
  }), " on the boss. After that wait for anybody else who is precasting skills and open the portal."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Moa Stance",
    id: "45970"
  }), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Call of the Wild",
    id: "12621"
  }), " to blast ", React.createElement(Boon, {
    name: "Might"
  }), ", then use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), "."), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity"), " and swap to Longbow."), "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " before the boss is activated."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "1st Phase (100%-80%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Frosttrap",
    id: "12492"
  }), " as soon as your first trap procs so you have both ticking at the same time."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Sicem",
    id: "12633"
  }), " as soon as Arkk is targetable."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pointblankshot",
    id: "12511"
  }), " to proc ", React.createElement(Item, {
    id: "84505",
    id: "84505"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Rapidfire",
    id: "12509"
  }), " if you have time, then walk through Arkk so you avoid the ", React.createElement(Condition, {
    name: "Fear"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Worldlyimpact",
    id: "40729"
  }), " and Swap weapons."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pathofscars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Whirling defense",
    id: "12639"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "frenziedattack",
    id: "43548"
  }), " and autoattack with sword if phase is not over."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Bloom phases")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "We Heal as One",
    id: "31914"
  }), " then run into the lightning AoE, autoattack the orb once and then use ", React.createElement(Skill, {
    id: "12482",
    id: "12482"
  }), " to finish pushing."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "2nd Phase (80%-70%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use special action key and ", React.createElement(Skill, {
    name: "Pathofscars",
    id: "12638"
  }), " to CC."), "\n", React.createElement(_components.li, null, "Swap weapons."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Pointblankshot",
    id: "12511"
  }), ", ", React.createElement(Skill, {
    name: "Rapidfire",
    id: "12509"
  }), ", ", React.createElement(Skill, {
    name: "frenziedattack",
    id: "43548"
  }), " and ", React.createElement(Skill, {
    name: "Worldlyimpact",
    id: "40729"
  }), " until Arkk is phased."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Archdiviner")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "There is not much you can do here so use ", React.createElement(Skill, {
    name: "Rapidfire",
    id: "12509"
  }), " and autoattacks to help kill the add."), "\n", React.createElement(_components.li, null, "Use special action key to CC the add so the boon corrupt AoE's don't spawn."), "\n", React.createElement(_components.li, null, "As the add dies start casting ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  })), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "3rd Phase (70%-50%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "onewolfpack",
    id: "45717"
  }), " -> ", React.createElement(Skill, {
    name: "Sicem",
    id: "12633"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  }), " if it's off cooldown and then ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " to proc ", React.createElement(Item, {
    id: "84505",
    id: "84505"
  }), "."), "\n", React.createElement(_components.li, null, "Swap weapons."), "\n", React.createElement(_components.li, null, "Burst with ", React.createElement(Skill, {
    name: "Pathofscars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Whirling defense",
    id: "12639"
  }), "."), "\n", React.createElement(_components.li, null, "Continue with ", React.createElement(Skill, {
    name: "Worldlyimpact",
    id: "40729"
  }), ", ", React.createElement(Skill, {
    name: "Frenzied Attack",
    id: "43548"
  }), " and sword autoattacks to finish the phase."), "\n", React.createElement(_components.li, null, "If the phase is slow you may have time to swap to Longbow and use ", React.createElement(Skill, {
    name: "Point Blank Shot",
    id: "12511"
  }), " and ", React.createElement(Skill, {
    name: "Rapid Fire",
    id: "12509"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Bloom phases")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Swap to longbow and auto-attack to push the orb."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "We Heal as One",
    id: "31914"
  }), " to keep up boons and precast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "4th Phase (50%-40%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use special action key to CC."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pointblankshot",
    id: "12511"
  }), " to proc ", React.createElement(Item, {
    id: "84505",
    id: "84505"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Rapidfire",
    id: "12509"
  }), ", ", React.createElement(Skill, {
    name: "Worldlyimpact",
    id: "40729"
  }), ", ", React.createElement(Skill, {
    name: "frenziedattack",
    id: "43548"
  }), " until Arkk is phased."), "\n", React.createElement(_components.li, null, "If the phase is slow swap to Sword/Axe and finish the phase with auto attacks."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Gladiator Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Frosttrap",
    id: "12492"
  }), "."), "\n", React.createElement(_components.li, null, "Pull one add with ", React.createElement(Skill, {
    name: "Path of scars",
    id: "12638"
  }), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Sicem",
    id: "12633"
  }), " (on the gladiator!) and ", React.createElement(Skill, {
    name: "whirlingdefense",
    id: "12639"
  }), " to kill him fast."), "\n", React.createElement(_components.li, null, "If the Gladiator isn't dead, dodge the laser, CC with special action key and finish it off with auto attacks."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "5th Phase (40%-30%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Auto-attack until ", React.createElement(Skill, {
    name: "Path of scars",
    id: "12638"
  }), " is off cd, then use it to pull a second add."), "\n", React.createElement(_components.li, null, "Swap weapons."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pointblankshot",
    id: "12511"
  }), " to proc ", React.createElement(Item, {
    id: "84505",
    id: "84505"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Rapidfire",
    id: "12509"
  }), " followed by ", React.createElement(Skill, {
    name: "Worldlyimpact",
    id: "40729"
  }), " and ", React.createElement(Skill, {
    name: "frenziedattack",
    id: "43548"
  }), " if it's off cooldown until Arkk is phased."), "\n", React.createElement(_components.li, null, "Feel free to burst here, just make sure you will have your cooldowns back fo the start of the last phase!"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Bloom phases")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Stay on longbow and auto-attack to push the orb."), "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "6th Phase (30%-0%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Sicem",
    id: "12633"
  }), " as soon as the boss is vulnerable -> ", React.createElement(Skill, {
    name: "onewolfpack",
    id: "45717"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Frost trap",
    id: "12492"
  }), " and special action key to CC."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pointblankshot",
    id: "12511"
  }), " to proc ", React.createElement(Item, {
    id: "84505",
    id: "84505"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Rapidfire",
    id: "12509"
  }), "."), "\n", React.createElement(_components.li, null, "Swap weapons."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pathofscars",
    id: "12638"
  }), " -> ", React.createElement(Skill, {
    name: "Whirling defense",
    id: "12639"
  }), "."), "\n", React.createElement(_components.li, null, "Continue with the normal rotation until Arkk is dead."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
